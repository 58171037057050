import React from 'react'
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import axios from 'axios';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import Button from '@material-ui/core/Button';
import apiServerUrl from './apiServerUrl'
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    dividerFullWidth: {
      margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    dividerInset: {
      margin: `5px 0 0 ${theme.spacing(9)}px`,
    },
  }));

function ScanPage() {
    const [ addPhotos, setAddPhotos ] = React.useState("true");
    const [ btnsend, setBtnsend ] = React.useState("true");
    const [ service, setService ] = React.useState({"barcode": "Empty", "client": "Not Defined", "cellphone": "Not Defined", "status": "", "invoice":""});
    const classes = useStyles();
    let history = useHistory();
    
    const markReady = () => {
        axios.put(`https://app.rrhwheel.com/api/barcode/${service.barcode}/`)
        .then(res => {
            setBtnsend("true")
            alert("Item Fixed")
        })
        .catch(function(error){
            alert(error)
        })
    }

    const addPhotosFunc = () => {
        //to={"/invoice/" + service.id}
        history.replace('/invoice/'+service.invoice);
    }
    
    const sendData = (val) => {
        axios.get(`https://app.rrhwheel.com/api/barcode/${val}/`)
        .then(res => {
            setService({'barcode': val, 'client': res.data.client, 'cellphone': res.data.cellphone, 'status': res.data.status, 'id': res.data.id, 'invoice':res.data.invoice});
            if(res.data.status == false){
                setBtnsend(false)
            }
            setAddPhotos(false)
        })
        .catch(function(error){
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                /* alert(error.response.data);
                alert(error.response.status);
                alert(error.response.headers); */
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                alert(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                alert('Error', error.message);
              }
        })
    }

    //sendData('TR10000001')
         
    return (
        <>
            <BarcodeScannerComponent
                width={"100%"}
                height={"20%"}
                onUpdate={(err, result) => {
                    if (result){
                        sendData(result.text)
                    }/* else{setData('Not found')}; */
                    //sleepFor(3000);
                    
                }}
            />
            <List className={classes.root}>
                <ListItem>
                    <ListItemText primary="Client" secondary={service.client + " - " + service.cellphone} />
                </ListItem>
                <Divider component="li" />
                <ListItem>
                    <ListItemText primary="Item" secondary={service.barcode} />
                    <Button variant="contained" color="primary" onClick={markReady} disabled={btnsend == "true" ? true : false}>Mark ready</Button> 
                    <Button variant="contained" color="success" onClick={addPhotosFunc} disabled={addPhotos == "true" ? true : false}>Add photo</Button>
                </ListItem>
                <br />
            </List>
        </>
    )
}

export default ScanPage
