import React, {useEffect, useState} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios';
import apiServerUrl from './apiServerUrl'

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: "100%",
      margin: "5%"
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
  }));

function InvoicePage() {
    const { id } = useParams()
    const [invoice, setInvoice] = useState({number: '000000', client_name: '------', date: '--/--/--'});
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    var userToken = localStorage.getItem('usertoken');

    const classes = useStyles();

    function handleTakePhoto (dataUri) {
      //alert(dataUri)
      axios.post(`${apiServerUrl}/api/invoice/${id}/images/`, {'image': dataUri}, { headers: {"Authorization" : `JWT ${userToken}`} })
      .then(res => {
          setImages(res.data)
          setLoading(false);
          console.log(images)
      })
      .catch(function(error){
          console.log(error)
          setLoading(false);
      })
    }

    useEffect(() => {
        setLoading(true);
        
        axios.get(`${apiServerUrl}/api/invoice/${id}/`, { headers: {"Authorization" : `JWT ${userToken}`} })
        .then(res => {
            setInvoice(res.data)
            setLoading(false);
            console.log(invoice)
        })
        .catch(function(error){
            console.log(error)
            setLoading(false);
        })

        axios.get(`${apiServerUrl}/api/invoice/${id}/images/`, { headers: {"Authorization" : `JWT ${userToken}`} })
        .then(res => {
            setImages(res.data)
            setLoading(false);
            console.log(images)
        })
        .catch(function(error){
            console.log(error)
            setLoading(false);
        })

      }, [setInvoice, setImages]);

    return (
        <div>
            <Card className={classes.root}>
                <CardHeader
                    avatar={
                    <Avatar aria-label="recipe" className={classes.avatar}>
                        I
                    </Avatar>
                    }
                    title={"INVOICE " + invoice.number}
                    subheader={invoice.date}
                />
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Client: {invoice.client_name}<br />
                        Comments: {invoice.comments}<br />
                        Message: {invoice.message}<br />
                    </Typography>
                </CardContent>
                {/* <CardActions disableSpacing>
                    <IconButton aria-label="add to favorites" onClick={handleExpandClick}>
                        <AddAPhotoIcon />
                    </IconButton>
                </CardActions> */}
            </Card>
            
            {images.map((item, i) => (
              <Card className={classes.root} key={i}>
                  <CardMedia
                    component="img"
                    alt="Image View"
                    height="350px"
                    image={apiServerUrl + item.image}
                    title="Image View"
                  />
              </Card>
            ))}

            <Camera
              onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
              imageType = {IMAGE_TYPES.JPG}
              idealFacingMode = {FACING_MODES.ENVIRONMENT}
              isImageMirror = {false}
            />

            <br /><br /><br />

        </div>
    )
}

export default InvoicePage
