import React, {useEffect, useState} from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import apiServerUrl from './apiServerUrl'

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
  }));

function MainPage() {
    const [invoices, setInvoices] = useState([{number: '000000', client_name: '------', date: '--/--/--'}]);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        setLoading(true);
        var userToken = localStorage.getItem('usertoken');
        axios.get(`${apiServerUrl}/api/invoice/`, { headers: {"Authorization" : `JWT ${userToken}`} })
        .then(res => {
            setInvoices(res.data.results)
            setLoading(false);
        })
        .catch(function(error){
            console.log(error)
            setLoading(false);
        })

      }, [setInvoices]);
    return (
        <div>        
            <div align="center"><h2>INVOICES LISTS{loading}</h2></div>
            <List className={classes.root}>
                {invoices.map((item, i) => (
                    <Link to={"/invoice/" + item.id} style={{ textDecoration: 'none' }} key={i}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <WorkIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={item.number + ' - ' + item.client_name} secondary={item.date} />
                        </ListItem>
                    </Link>
                ))}
            </List>
        </div>
    )
}

export default MainPage