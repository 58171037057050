import React from 'react'
import LogoutButton from './LogoutButton';

function MainPage() {
    return (
        <div align="center">
            <LogoutButton />
        </div>
    )
}

export default MainPage
