import React, { useContext, createContext, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";

import MainPage from './components/MainPage.js';
import LoginPage from './components/LoginPage.js';
import ProfilePage from './components/ProfilePage.js';
import InvoicePage from './components/InvoicePage.js';

import ScanPage from './components/ScanPage.js';
import SimpleBottomNavigation from './components/SimpleBottomNavigation.js';
import authContext from './components/authContext';

function App() {  
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <Route exact path="/scan" component={ScanPage} />
          <AuthenticatedRoute exact path="/">
            <Route exact path="/" component={LoginPage} />  
          </AuthenticatedRoute>
          <PrivateRoute exact path="/home">
            <MainPage />
          </PrivateRoute>
          <PrivateRoute exact path="/profile">
            <ProfilePage />
          </PrivateRoute>
          <PrivateRoute exact path="/invoice/:id">
            <InvoicePage />
          </PrivateRoute>
        </Switch>
        <SimpleBottomNavigation />
      </Router>
    </ProvideAuth>
  );
}

function PrivateRoute({ children, ...rest }) {
  let auth = useContext(authContext);
  var userToken = localStorage.getItem('usertoken');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        userToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function AuthenticatedRoute({ children, ...rest }) {
  let auth = useContext(authContext);
  var userToken = localStorage.getItem('usertoken');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        !userToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/home",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}


const fakeAuth = {
  isAuthenticated: false,
  signin(cb) {
    fakeAuth.isAuthenticated = true;
    setTimeout(cb, 100); // fake async
  },
  signout(cb) {
    fakeAuth.isAuthenticated = false;
    setTimeout(cb, 100);
  }
};

function useProvideAuth() {
  const [user, setUser] = useState(null);

  const signin = cb => {
    return fakeAuth.signin(() => {
      setUser("user");
      cb();
    });
  };

  const signout = cb => {
    return fakeAuth.signout(() => {
      setUser(null);
      cb();
    });
  };

  return {
    user,
    signin,
    signout
  };
}


export default App;
