import React, { useContext, createContext, useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import logo from '../assets/logo.png';
import Card from '@material-ui/core/Card';
import axios from 'axios';
import authContext from './authContext';
import { useHistory, useLocation } from "react-router-dom";
import apiServerUrl from './apiServerUrl'

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '90%',
      },
    },
    main:{
        verticalAlign: 'bottom',
        height: "85vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    }
  }));

function LoginPage(props) {
    const classes = useStyles();
    const [ user, setUser ] = React.useState({"username": "", "password": ""});
    const [ btnsend, setBtnsend ] = React.useState("false");
    const value = useContext(authContext);

    ///STARTY AUTH
    let history = useHistory();
    let location = useLocation();
  
    let { from } = location.state || { from: { pathname: "/" } };
    let login = () => {
      value.signin(() => {
        history.replace('/home');
      });
    };
    ///END AUTH

    const handleChangeUsername = event => {
        setUser({...user, 'username': event.target.value})
        //console.log(user.username + ' ' + user.password)
    }
    const handleChangePassword = event => {
        setUser({...user, 'password': event.target.value})
        //console.log(user.username + ' ' + user.password)
    }
    const handleSubmit = event => {
        event.preventDefault();
        setBtnsend("true")
        if(user.username == '' || user.password == ''){
            alert("You must define a username and password")
            setBtnsend("false")
            return
        }
        //https://app.rrhwheel.com
        axios.post(`${apiServerUrl}/api/login/`, {"username": user.username, "password": user.password})
        .then(res => {
            //console.log(res.data.token)
            localStorage.setItem('usertoken', res.data.token)
            login()
            //console.log(localStorage.getItem('usertoken'))
            //localStorage.removeItem('usertoken')
        })
        .catch(function(error){
            setBtnsend("false")
            alert(error)
        })
    }
    
    /* useEffect(() => {
        var userToken = localStorage.getItem('usertoken');
        if(!userToken){
            history.replace('/');
        }else{
            history.replace('/home');
        }
    }); */

    return (
        <Container maxWidth="sm" className={classes.main}>
            <img src={logo} className="App-logo" alt="logo" height="20%" /><br />
            <Card>
            <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <br />
                <TextField id="standard-basic" label="Username" onChange={handleChangeUsername} />
                <TextField id="standard-basic" label="Password" type="password" autoComplete="current-password" onChange={handleChangePassword} /><br /><br />
                <Button variant="contained" color="primary" type="submit" disabled={user.username === "" || user.password === "" || btnsend == "true" ? true : false}>Login</Button>
                <br /><br />
            </form>
            </Card>
        </Container>
    )
}

export default LoginPage
