import React, {useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CropFreeIcon from '@material-ui/icons/CropFree';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import HomeIcon from '@material-ui/icons/Home';
import { useHistory } from "react-router-dom";
import authContext from './authContext';

const useStyles = makeStyles({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
});

export default function SimpleBottomNavigation() {
  const classes = useStyles();
  const [value, setValue] = React.useState();
  const history = useHistory();
  var userToken = localStorage.getItem('usertoken');
  const auth = useContext(authContext);

  const goTo = (val) => {
      if(val === 0){
        history.push('/')
      }else if(val === 1){
        history.push('/scan')
      }else if(val === 2){
        history.push('/profile')
      }
  }

  if (!userToken) {
    var bottomMain = <BottomNavigationAction label="Login" icon={<AccountCircleIcon />} />
    var bottomSecond = '';
  } else {
    var bottomMain = <BottomNavigationAction label="Home" icon={<HomeIcon />} />
    var bottomSecond = <BottomNavigationAction label="Profile" icon={<AccountBoxIcon />} />
  }

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        goTo(newValue)
      }}
      showLabels
      className={classes.root}
    >
      {bottomMain}
      <BottomNavigationAction label="Barcode" icon={<CropFreeIcon />} />
      {bottomSecond}
    </BottomNavigation>
  );
}