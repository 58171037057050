import React from 'react'
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(2),
      },
    },
  }));

function LogoutButton() {
    const classes = useStyles();
    const history = useHistory();

    const logout = () => {
        localStorage.removeItem('usertoken');
        history.push('/')
    }

    return (
        <div className={classes.root}>
            <Button variant="outlined" onClick={logout}>Logout</Button>
        </div>

    )
}

export default LogoutButton
